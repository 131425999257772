<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		title="Add attributes"
		data-test-id="modal-add-attribute"
		class="modal-add-attribute"
		centered
	>
		<template #default>
			<BaseDropDown
				:options="attributeKeyOptions"
				:value="selectedKeysValue"
				:is-valid="isValid"
				:close-on-select="false"
				class="multiple-options"
				label="name"
				label-drop-down=""
				multiple
				@select="handleSelectedKeys"
				@remove="handleRemoveKey"
			/>
			<small class="form-text text-muted w-100" :class="{ invalid: !isValid }">Maximum {{ maxLength }} attributes per set</small>
		</template>
		<template #footer>
			<CButton
				color="tertiary"
				class="mr-3"
				@click="isShow = false"
			>
				Cancel
			</CButton>
			<CButton
				color="primary"
				@click="handleConfirm"
			>
				Add
			</CButton>
		</template>
	</CModal>
</template>

<script>
export default {
	name: 'AttributeSetEditModalKeys',
	props: {
		attributeKeys: {
			type: Array,
			default: () => [],
		},
		selectedKeys: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			isValid: true,
			maxLength: 30,
			newSelectedIds: [],
		};
	},
	computed: {
		attributeKeyOptions() {
			return this.attributeKeys.map((key) => ({
				name: key.name,
				value: key.id,
			}));
		},
		oldSelectedKeys() {
			return this.selectedKeys.map((key) => key.id);
		},
		selectedKeysValue() {
			return this.attributeKeyOptions.filter((key) => this.newSelectedIds.some((id) => id === key.value));
		},
	},
	methods: {
		open() {
			this.newSelectedIds = [];
			this.isValid = true;
			this.isShow = true;
		},
		handleConfirm() {
			const totalKeys = [...new Set([...this.oldSelectedKeys, ...this.newSelectedIds])];

			if (totalKeys.length > this.maxLength) {
				this.isValid = false;
			} else {
				const newKeyIds = totalKeys.filter((key) => !this.oldSelectedKeys.some((id) => id === key));

				this.$emit('onAdd', newKeyIds);
				this.isShow = false;
			}
		},
		handleSelectedKeys(option = null) {
			this.newSelectedIds.push(option.value);
		},
		handleRemoveKey(option = null) {
			this.newSelectedIds = this.newSelectedIds.filter((id) => id !== option.value);
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .form-group {
		margin-bottom: rem(10);
	}

	.form-text {
		// Override helper text color
		&.invalid {
			color: $color-alert !important;
		}
	}
</style>
